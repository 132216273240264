import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../i18n"
import ReferencesComponent from "../components/ReferencesComponent"

require("typeface-roboto")

const ReferencesPage = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState("es")
  const onChangeLanguage = () => {
    i18n.changeLanguage(language)
    if (language === "es") {
      setLanguage("en")
    } else {
      setLanguage("es")
    }
  }

  return (
    <Layout>
      <SEO title={t("title.references")} />
      <ReferencesComponent />
    </Layout>
  )
}

export default ReferencesPage
