import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, Container, Box, Divider, Paper } from "@material-ui/core"
import "../styles/general.css"
import Footer from "./Footer"

import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import { red } from "@material-ui/core/colors"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

const ReferencesComponent = () => {
  const { t, i18n } = useTranslation()

  const classes = useStyles()

  return (
    <div className="componentsDiv">
      <Box align="justify">
        <h1 className="titlesGradientH1">{t("title.references")}</h1>
        <Divider />
        <br />
        <h2 className="titlesGradientH2">{t("references.academictitle")}</h2>
        <Card className={classes.root} variant="outlined" square>
          <CardHeader
            title="Dr. Carlos Alberto Gaviria Neira"
            subheader={t("references.carlosgaviria")}
          />
        </Card>
        <br />
        <Card className={classes.root} variant="outlined" square>
          <CardHeader
            title="Dr. Hugo Ramírez Chaparro"
            subheader={t("references.hugoramirez")}
          />
        </Card>
        <br />
        <h2 className="titlesGradientH2">
          {t("references.professionaltitle")}
        </h2>
        <Card className={classes.root} variant="outlined" square>
          <CardHeader
            title="Dr. Sandra Cortés"
            subheader={t("references.sandracortes")}
          />
        </Card>
        <br />
      </Box>
      <br />
      <Box>
        <Footer />
      </Box>
    </div>
  )
}

export default ReferencesComponent
